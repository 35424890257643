// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".urHut38GeM_gQSh9its4 {\r\n  display: flex;\r\n  gap: 8px;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-color: #2d2d2d;\r\n}\r\n\r\n@media (min-width: 800px) {\r\n  .urHut38GeM_gQSh9its4 {\r\n    max-width: 1040px;\r\n    max-height: 800px;\r\n  }\r\n}", "",{"version":3,"sources":["webpack://./src/components/ChatView/chatview.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE;IACE,iBAAiB;IACjB,iBAAiB;EACnB;AACF","sourcesContent":[".main {\r\n  display: flex;\r\n  gap: 8px;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-color: #2d2d2d;\r\n}\r\n\r\n@media (min-width: 800px) {\r\n  .main {\r\n    max-width: 1040px;\r\n    max-height: 800px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "urHut38GeM_gQSh9its4"
};
export default ___CSS_LOADER_EXPORT___;
