// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FeR27YfMir_XNFOae97z {\r\n  background-color: #3c3c3c;\r\n  height: 100vh;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  padding: 0 8px;\r\n}\r\n\r\n@media (min-width: 800px) {\r\n  .FeR27YfMir_XNFOae97z {\r\n    background-color: #2d2d2d;\r\n    padding: 16px;\r\n  }\r\n}", "",{"version":3,"sources":["webpack://./src/components/Layout/layout.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE;IACE,yBAAyB;IACzB,aAAa;EACf;AACF","sourcesContent":[".section {\r\n  background-color: #3c3c3c;\r\n  height: 100vh;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  padding: 0 8px;\r\n}\r\n\r\n@media (min-width: 800px) {\r\n  .section {\r\n    background-color: #2d2d2d;\r\n    padding: 16px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "FeR27YfMir_XNFOae97z"
};
export default ___CSS_LOADER_EXPORT___;
