// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bxRF9fa71RAcmWoQMoX_ {\r\n  border-radius: 6px;\r\n  padding: 20px;\r\n  background-color: #3c3c3c;\r\n  width: 400px;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.Nr96ywS8vPb8dispYm2S {\r\n  font-size: 24px;\r\n  font-weight: 700;\r\n  margin-bottom: 30px;\r\n}\r\n\r\n.eazqGsFSvsk_c2G6DYuQ {\r\n  height: 100%;\r\n  overflow-y: scroll;\r\n}\r\n\r\n.eazqGsFSvsk_c2G6DYuQ::-webkit-scrollbar {\r\n  width: 4px;\r\n}\r\n\r\n.eazqGsFSvsk_c2G6DYuQ::-webkit-scrollbar-track {\r\n  background: transparent;\r\n}\r\n\r\n.eazqGsFSvsk_c2G6DYuQ::-webkit-scrollbar-thumb {\r\n  border-radius: 4px;\r\n  border: 3px solid #4d4d4d;\r\n}\r\n\r\n.SBDMi8kBTh6VToAjwL7C {\r\n  margin-bottom: 6px;\r\n}\r\n\r\n.uOIPAfFljgES4KaVcI6u {\r\n  font-weight: 700;\r\n}", "",{"version":3,"sources":["webpack://./src/components/ConnectedUsers/connectedUsers.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".section {\r\n  border-radius: 6px;\r\n  padding: 20px;\r\n  background-color: #3c3c3c;\r\n  width: 400px;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.h3 {\r\n  font-size: 24px;\r\n  font-weight: 700;\r\n  margin-bottom: 30px;\r\n}\r\n\r\n.listOfUsers {\r\n  height: 100%;\r\n  overflow-y: scroll;\r\n}\r\n\r\n.listOfUsers::-webkit-scrollbar {\r\n  width: 4px;\r\n}\r\n\r\n.listOfUsers::-webkit-scrollbar-track {\r\n  background: transparent;\r\n}\r\n\r\n.listOfUsers::-webkit-scrollbar-thumb {\r\n  border-radius: 4px;\r\n  border: 3px solid #4d4d4d;\r\n}\r\n\r\n.username {\r\n  margin-bottom: 6px;\r\n}\r\n\r\n.bold {\r\n  font-weight: 700;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": "bxRF9fa71RAcmWoQMoX_",
	"h3": "Nr96ywS8vPb8dispYm2S",
	"listOfUsers": "eazqGsFSvsk_c2G6DYuQ",
	"username": "SBDMi8kBTh6VToAjwL7C",
	"bold": "uOIPAfFljgES4KaVcI6u"
};
export default ___CSS_LOADER_EXPORT___;
